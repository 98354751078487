import React from 'react'
import { Link } from 'gatsby'

export const BlogItem = ({node}) => (
    <div key={node.id} className="border-2 mb-4 border-gray-800 p-4 bg-white font-sans">
        <h3 className="mb-1 hover:underline">
            <Link to={'blog' + node.fields.slug + "#page_title"}>{node.frontmatter.title}{" "}</Link>
        </h3>
        <p className="text-xs">
            {node.frontmatter.date}
        </p>
        <p className="mb-0">{node.excerpt}</p>
    </div>
)