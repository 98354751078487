import { graphql } from "gatsby"
import React from "react"
import { BlogItem } from "../components/blog/blog-item"
import Layout from "../components/layout"


export default function BlogTagPage({data}) {
    console.log(data)
  return (
    <Layout>
      <div>
        <h1 id="page_title">
          Blog Posts
        </h1>
        <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <BlogItem node={node} key={"item_" + node.id} />
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($tag: String!) {
    allMarkdownRemark(filter: {frontmatter: {tags: {eq: $tag}}}, sort: {fields: frontmatter___date, order: DESC}) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "DD MMMM, YYYY")            
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  `